import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const YeTrackingInfo = () => import('@/entities/ye-tracking-info/ye-tracking-info.vue');
// prettier-ignore
const YeTrackingInfoUpdate = () => import('@/entities/ye-tracking-info/ye-tracking-info-update.vue');
// prettier-ignore
const YeTrackingInfoDetails = () => import('@/entities/ye-tracking-info/ye-tracking-info-details.vue');
const YeImport = () => import('@/entities/ye-tracking-info/ye-import.vue');
// prettier-ignore
const CarrierInfo = () => import('@/entities/carrier-info/carrier-info.vue');
// prettier-ignore
const CarrierInfoUpdate = () => import('@/entities/carrier-info/carrier-info-update.vue');
// prettier-ignore
const CarrierInfoDetails = () => import('@/entities/carrier-info/carrier-info-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'ye-tracking-info',
      name: 'YeTrackingInfo',
      component: YeTrackingInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ye-tracking-info/new',
      name: 'YeTrackingInfoCreate',
      component: YeTrackingInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ye-tracking-info/:yeTrackingInfoId/edit',
      name: 'YeTrackingInfoEdit',
      component: YeTrackingInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ye-tracking-info/:yeTrackingInfoId/view',
      name: 'YeTrackingInfoView',
      component: YeTrackingInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ye-tracking-info/import',
      name: 'YeImportView',
      component: YeImport,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'carrier-info',
      name: 'CarrierInfo',
      component: CarrierInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'carrier-info/new',
      name: 'CarrierInfoCreate',
      component: CarrierInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'carrier-info/:carrierInfoId/edit',
      name: 'CarrierInfoEdit',
      component: CarrierInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'carrier-info/:carrierInfoId/view',
      name: 'CarrierInfoView',
      component: CarrierInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
